.wrapper {
  /* max-height: 500px; */
  /* border: 1px solid white; */
  padding-top: 20px;
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
}

.service-heading {
  font-size: 50px;
  color: white;
  padding-top: 25px;
  padding-bottom: 25px;
  font-weight: 500;
  margin-bottom: 50px;
}

.wrapper::-webkit-scrollbar {
  width: 0;
}

.service-img {
  background-image: url("../../Assets/crab-networks-services.jpg");
  background-repeat: no-repeat;
  height: 100%;
}

.background-overlay {
  background-image: linear-gradient(90deg, #161616bf 38%, #000000 86%);
  height: 100%;
}

.wrapper .box {
  min-width: 350px;
  height: 500px;
  margin-right: 20px;
  margin-left: 10px;
  border-radius: 5px;
}

.wrapper .box1 {
  border: 2px solid white;
  background-image: linear-gradient(
    215deg,
    transparent 30%,
    #1c72be 50%,
    #5c9783 70%
  );
}

.wrapper .box2 {
  border: 2px solid white;
  border-radius: 10px;
  background-image: linear-gradient(
    215deg,
    transparent 30%,
    #f5d296 50%,
    #f67510 70%
  );
}

.wrapper .box3 {
  border: 2px solid white;
  border-radius: 10px;
  background-image: linear-gradient(
    215deg,
    transparent 30%,
    #d5c496 50%,
    #676348 70%
  );
}

.wrapper .box4 {
  border: 2px solid white;
  border-radius: 10px;
  background-image: linear-gradient(
    215deg,
    transparent 30%,
    #f7bb45 50%,
    #a13b1a 70%
  );
}

.wrapper .box5 {
  border: 2px solid white;
  border-radius: 10px;
  background-image: linear-gradient(
    215deg,
    transparent 30%,
    #1c72be 50%,
    #5c9783 70%
  );
}

.box .boxImg img {
  width: 200px;
  /* filter: drop-shadow(6px 0px 7px orange); */
  /* transition: 5s, transform 5s; */
}

/* .box .boxImg img:hover {
    transform: rotate(360deg);
} */

.serviceBox {
  display: flex;
  flex-wrap: wrap;
  padding-left: 70px;
}

.see_all {
  display: flex;
  justify-content: center;
  color: white;
  margin-top: 60px;
}

/* 
.serviceBox .service-card {
  min-width: 105px;
} */

.service-card {
  border: 2px solid #4e4a4a;
  background-color: white;
  color: black;
}
 
.service-card:hover img {
  transform: translateY(-5px);
  background-color: #f67410;
} 

.service-card img {
  margin-top: 10px;
  margin-left: 12px;
  width: 45px;
  border: 1px solid #777;
  height: 45px;
  transition: all 0.5s ease-in-out;
  padding: 8px;
  border-radius: 50%;
}

.service-card h1 {
  font-size: 13px;
  padding-top: 5px;
}

.boxImg {
  margin-top: 10px;
}

.boxBeading {
  font-size: 40px;
  font-weight: bold;
  margin-left: 20px;
}

.para {
  padding-right: 20px;
  margin-top: 20px;
  margin-left: 20px;
  text-align: justify;
}

.sub-heading {
  color: #fff;
  font-family: "cera-light", Sans-serif;
  font-size: 25px;
  font-weight: 300;
}

.paragraph {
  color: #bcbcbc;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.2px;
}

@media screen and (max-width: 600px) {
  .paragraph {
    text-align: center;
  }
  .sub-heading {
    text-align: center;
  }
  .serviceBox {
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    padding-left: 0px;
  }
  .serviceBox .service-card {
    width: 160px !important;
  }
  .serviceBox .service-card h1 {
    font-size: 10px;
  }
}