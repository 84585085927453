.simple-cards {
  margin-top: 100px;
  margin-bottom: 100px;
}

.my-img {
  display: flex;
  margin: auto;
  width: 40%;
}

.card-heading {
  color: #fff;
  font-family: "cera-light", Sans-serif;
  font-size: 17px;
  font-weight: 200;
  letter-spacing: 2px;
  text-align: center;
}

.card-subbody {
  text-align: center;
  color: #989898;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  letter-spacing: 0;
}

@media screen and (max-width: 600px) {
  .simple-cards {
    margin-top: 1rem;
  }
}
