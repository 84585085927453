/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,700;1,100;1,700;1,800;1,900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,600;0,700;0,900;1,100;1,200;1,600&family=Montserrat:ital,wght@0,100;0,200;0,700;1,100;1,700;1,800;1,900&display=swap");
.rlr-banner__media video {
  height: 100vh;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.icon-container {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px;
  z-index: 9999;
}

.fixed_whatsapp {
  width: 100px;
}

.heading {
  margin-top: 7rem;
}

.right-sections img {
  display: flex;
  height: 50vh;
  transform: rotate(-20deg);
  animation: moveInSpace 10s ease-in-out infinite alternate;
}

@keyframes moveInSpace {
  50% {
    transform: translateY(100px);
  }
}

.rlr-banner__media .rlr-callout {
  position: relative;
}

.rlr-banner__media .video-container {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.Logo {
  height: 60px;
}

.navbar-button {
  background-color: #f67510;
  color: white;
  font-weight: 600;
}

.heading h1 {
  font-size: 85px;
  color: white;
  line-height: 60px;
  margin-left: 55px;
  font-family: "Kanit", sans-serif;
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: "Montserrat", sans-serif; */
}

.heading > h1:hover {
  color: #f67510;
  cursor: pointer;
}

.heading > button {
  background-color: #f67510;
  color: WHITE;
  margin-top: 10%;
  width: 200px;
  height: 50px;
  font-weight: 600;
  border: none;
  margin-left: 55px;
}

.logo-hover {
  display: none;
}

.logo-hover:hover {
  display: block !important;
}

.logo-hover img {
  height: 60px;
}

.rlr-banner__media.sideImg {
  display: block;
}

/* @media (min-width: 576px) and (max-width: 991.98px) {
  .rlr-banner__media .video-container {
    height: 820vh;
  }
} */

.slogan {
  margin-left: 55px;
  line-height: 1;
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .rlr-banner__media .video-container video {
    height: 100vh;
    width: 100%;
  }
  .navbar-toggler {
    background: #b0947e;
    margin-left: 5rem;
  }
  .astronaut {
    position: relative;
  }
  .right-sections {
    position: absolute;
    top: -25rem;
    left: 15rem;
  }
  .right-sections img {
    height: 20vh;
    margin-top: 70px;
  }
  .heading h1 {
    font-size: 35px !important;
    line-height: 55px;
    /* margin-top: 10px; */
    margin-left: 0px;
    margin-bottom: -8px;
  }
  .heading {
    padding-top: 3rem;
  }
  .slogan {
    margin-left: 0px;
    font-size: 15px;
    text-align: center;
  }
  .icon-container {
    display: flex;
    margin-right: auto;
    margin-bottom: 20%;
    /* display: none; */
  }
}

/* @media (min-width: 1200px) {
  .rlr-banner__media .type-d1 {
    font-size: 6rem;
  }
} */
