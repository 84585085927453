.mobileScreen {
  display: none;
}

.menuBg {
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
  /* background: linear-gradient(to right, #fe5f75, #fc9842); */
  background: rgb(67, 7, 120);
  background: linear-gradient(95deg, rgb(161 161 161) 0%, rgb(33 31 33) 100%);
  padding: 5px;
  border-radius: 10px 10px 0px 0px;
}

.menuIcons {
  display: flex;
  justify-content: space-around;
}

.iconeList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iconeList .icon {
  font-size: 20px;
  background-color: transparent;
  border: none;
  color: white;
}

.iconName {
  font-size: 10px;
}

.icon:focus {
  color: #fe5f75;
}

@media (max-width: 600px) {
  .mobileScreen {
    display: flex;
    padding: 20px;
  }
}
