.s-headgin {
  font-size: 60px;
  padding-top: 20px;
}

.orange {
  background-color: #f67510;
  height: 100%;
}

.card1 {
  background: transparent;

  border: none !important;
}

.card1 img {
  filter: drop-shadow(6px 0px 7px #f67510);
}

@media screen and (max-width: 600px) {
  .s-headgin {
    font-size: 35px;
    font-weight: bold;
  }
}
