.about-media video {
  height: 70vh;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.about-header-img {
  height: 70vh;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.about-media .video-container {
  height: 70vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.aboutheading h1 {
  position: relative;
  font-size: 80px;
  top: 50%;
  color: white;
  line-height: 83px;
  font-weight: bold;
  text-align: center;
}

.aboutheading > h1:hover {
  color: #f67510;
  cursor: pointer;
}

.logo {
  position: relative;
}

/* @media (min-width: 576px) and (max-width: 991.98px) {
  .about-media .video-container {
    height: 820vh;
  }
} */

@media screen and (max-width: 600px) {
  .about-media .video-container video {
    height: 100vh;
    width: 100%;
  }
  .about-media .video-container {
    height: 92vh;
  }
  .navbar-toggler {
    background: #b0947e;
    margin-left: 5rem;
  }
  .sideImg {
    display: none;
  }
  .aboutheading h1 {
    font-size: 55px;
    line-height: 72px;
    margin-top: 0px;
    text-align: center;
  }
}
