@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f67510;
  border-radius: 10px;
}

#mySidenav button {
  position: fixed;
  /* margin-top: 2%; */
  /* Position them relative to the browser window */
  right: 0;
  /* Position them outside of the screen */
  transition: 0.3s;
  /* Add transition on hover */
  /* 15px padding */
  /* Set a specific width */
  text-decoration: none;
  /* Remove underline */
  font-size: 15px;
  /* Increase font size */
  color: white;
  /* White text color */
  border-radius: 25px 0 0 25px;
  z-index: 100;

  /* Rounded corners on the top right and bottom right side */
}

#mySidenav button:hover {
  right: 0;
  /* On mouse-over, make the elements appear as they should */
  width: 25%;
  height: 50%;
  background-image: url("./Assets/iso\ image.jpeg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #27418100;
}

/* Handle */

::-webkit-scrollbar {
  background-color: red;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 20px;
}

body {
  background-image: url("./Assets/Aout us Background.png");
  background-color: black;
  font-family: "Space Grotesk", sans-serif;
  color: white;
  overflow-x: hidden;
  scroll-behavior: unset;
}

* {
  padding: 0;
  margin: 0;
}

.bg-img {
  background-image: url("./Assets/Aout\ us\ Background.png");
  background-color: black;
  font-family: "Space Grotesk";
  width: 100vw;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 600px) {
  #mySidenav button:hover {
    right: 0;
    /* On mouse-over, make the elements appear as they should */
    width: 70%;
    height: 40%;
    background-image: url("./Assets/SL_081320_33820_28\ [Converted]-02.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #27418100;
  }
}
