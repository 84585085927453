.offices_heading {
  text-align: center;
  font-size: 40px;
  color: white;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 30px;
}

.line {
  color: #f67510;
}

.box1,
.box2 {
  text-align: center;
  border-right: 2px solid #f67510;
}

.box3 {
  text-align: center;
}
