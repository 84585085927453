@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,600;0,700;0,900;1,100;1,200;1,600&family=Montserrat:ital,wght@0,100;0,200;0,700;1,100;1,700;1,800;1,900&display=swap");

.service-media .banner-img {
  height: 100vh;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.service-media .video-container {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.contact-heading {
  margin-top: 200px;
  font-size: 100px;
  font-weight: 600;
  font-family: "Kanit", sans-serif;
}

.logo {
  position: relative;
}

.contactus-component {
  margin-top: 100px;
}

.left-side h1 {
  color: #f67510;
  font-weight: 500;
}

.left-side h1,
p {
  line-height: 2;
}

.right-side h1 {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 70px;
}

.email {
  margin-top: 100px;
}

.section-2 {
  margin-top: 100px;
}

.heading1 h1 {
  font-size: 50px;
  font-weight: bold;
}
.service_page_card1 {
  background-color: gray;
}

.service_page_title {
  font-weight: bold;
  text-align: center;
  font-size: 28px;
  color: white;
}
.service_page_card1 button {
  display: block;
  border: none;
  margin: auto;
  background-color: #f67510;
  margin-top: 30px;
}

.submit {
  background-color: #f67510;
  color: WHITE;
  margin-top: 5%;
  font-weight: 600;
  border: none;
}

.service-sub-text {
  font-size: 18px;
  text-align: center;
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  margin-top: 40px;
  line-height: 1.5;
}

@media screen and (max-width: 600px) {
  .service-media .video-container {
    height: 92vh;
  }
  .service-media .video-container video {
    height: 100vh;
    width: 100%;
  }
  .navbar-toggler {
    background: #b0947e;
    margin-left: 5rem;
  }
  .sideImg {
    display: none;
  }
  .contact-heading {
    font-size: 55px;
    margin-top: 70px;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* @media (min-width: 1200px) {
  .service-media .type-d1 {
    font-size: 6rem;
  }
} */

.first-heading {
  margin-top: 40px;
  font-size: 50px;
  font-weight: bold;
}
/* .service-component1 {
  background-image: url("../../../Assets/ui-service.png");
  height: 100vh;
  padding-left: 1rem;
  margin-top: 60px;
} */

/* .service-component1 ul {
  padding-left: 1rem;
  margin-top: 60px;
} */
.service-component9,
.service-component8,
.service-component7,
.service-component6,
.service-component5,
.service-component4,
.service-component3,
.service-component2,
.service-component1 p {
  /* margin-top: 30px; */
  font-size: 18px;
  color: #6f7a82;
}

.service-component1 .ux-design {
  margin-top: 30px;
  color: #6f7a82;
  /* font-size: 47px; */
}

.service-component1 h4 {
  margin-top: 30px;
}

.right-img {
  position: relative;
}

.service-component1 {
  padding: 50px 0px;
  background-image: url("../../../Assets/ui-service.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.ui-sublist li {
  list-style: circle;
  color: #6f7a82;
  font-size: 18px;
}

.ui-sublist {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.service-component2 {
  padding: 50px 0px;

  background-image: url("../../../Assets/new-service-mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* margin-bottom: 50px;  */
}

.service-component3 {
  padding: 50px 0px;
  background-image: url("../../../Assets/web-service.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.service-component5,
.service-component4 {
  padding: 50px 0px;

  background-color: #e9e8eb;
}
.service-component6 {
  padding: 50px 0px;

  background-color: #f9f9f9;
}
.service-component7 {
  padding: 50px 0px;

  background-color: #e9e8eb;
}
.service-component8 {
  padding: 50px 0px;

  background-color: #f9f9f9;
}

.filter {
  margin-top: 50px;
}
.filter_data h1 {
  margin-top: 30px;
  font-size: 60px;
}
.filter_data p {
  color: #b3b3b3;
  margin-top: 30px;
  font-size: 16px;
}
@media only screen and (max-width: 600px) {
  .right-img,
  .left-img img {
    display: none;
  }

  .service-sub-text {
    margin-top: -30%;
  }

  .service-page-img {
    display: none !important;
  }
  .service-component1 {
    height: auto;
  }
  .first-heading {
    margin-top: 26px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
  p {
    text-align: justify;
  }
  .service-component4 {
    height: auto;
    /* margin-bottom: 50px; */
  }
  .service-component3 {
    height: 100%;
  }
  .service-component2 {
    height: auto;
    /* margin-bottom: 50px; */
  }
  .service-component1 {
    height: auto;
  }
}
