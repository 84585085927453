/* .contact-headerimg {
  height: 50vh;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
} */

.contact-us video {
  height: 100vh;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.contact-us .video-container {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.contact-heading {
  margin-top: 100px;
  font-size: 100px;
  font-weight: bold;
}

.logo {
  position: relative;
}

.contactus-component {
  margin-top: 100px;
}

.left-side h1 {
  color: #f67510;
  font-weight: 500;
}

.left-side h1,
p {
  line-height: 2;
}

.contact-right-side h1 {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 70px;
  text-align: center;
}

.email {
  margin-top: 100px;
}

.section-2 {
  margin-top: 100px;
}

.heading1 h1 {
  font-size: 50px;
  font-weight: bold;
}

.submit {
  background-color: #f67510;
  color: WHITE;
  margin-top: 5%;
  font-weight: 600;
  border: none;
}

@media screen and (max-width: 600px) {
  .contact-us video {
    height: 92vh;
  }
  .contact-us .video-container {
    height: 92vh;
  }
  .contact-right-side h1 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 70px;
    text-align: left;
  }
  .navbar-toggler {
    background: #b0947e;
    margin-left: 5rem;
  }
  .sideImg {
    display: none;
  }
  .contact-heading {
    font-size: 50px;
    margin-top: 90px;
  }
}

/* @media (min-width: 1200px) {
  .service-media .type-d1 {
    font-size: 6rem;
  }
} */
