.component1 {
  background-image: linear-gradient(
    165deg,
    transparent 30%,
    #f7bb45 40%,
    #a13b1a 61%
  );
  height: 100vh;
}
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,600;0,700;0,900;1,100;1,200;1,600&family=Montserrat:ital,wght@0,100;0,200;0,700;1,100;1,700;1,800;1,900&display=swap");

.work-page-card-heading {
  font-size: 20px;
  font-weight: bold;
  font-family: sans-serif;
}
.card-subtext {
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  font-family: "Kanit", sans-serif;
}

.Details {
  background-color: #f67510;
  color: WHITE;
  margin-top: 30%;
  width: 200px;
  height: 50px;
  font-weight: 600;
  border: none;
}

.q-fashion-img1 {
  margin-top: 248px;
  position: absolute;
  z-index: 1;
  margin-right: 143px;
  width: 20%;
}

.q-fashion-img2 {
  margin-top: -110px;
  width: 80%;
  position: relative;
  transform: rotate(-5deg);
}

.Febcare-img1 {
  width: 80%;
  margin-top: -95px;
}
.cusine_tonite_img {
  width: 100%;
  margin-top: -65px;
}
.component2 {
  background-image: linear-gradient(
    193deg,
    transparent 30%,
    #f7bb45 40%,
    #a13b1a 61%
  );
  height: 100vh;
  margin-bottom: 50px;
}
.work_card {
  background-color: #595858;
  background-image: linear-gradient(45deg, #595858, transparent);
  color: white;
  border: none;
}

.work_card button {
  display: block;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .component1 {
    height: 100%;
    background-image: linear-gradient(
      162deg,
      transparent 10%,
      #f7bb45 29%,
      #a13b1a 61%
    );
  }
  .q-fashion-img1,
  .q-fashion-img2,
  .Febcare-img1 {
    display: none;
  }
  .q-fashion,
  .febcare {
    height: 100vh;
  }

  .worker-header-subtext {
    margin-top: -35%;
    line-height: 1.5;
  }
  .component2 {
    margin-bottom: 100px;
    height: 100%;
    background-image: linear-gradient(
      210deg,
      transparent 10%,
      #f7bb45 29%,
      #a13b1a 61%
    );
  }
}
