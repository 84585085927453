/* .work-headerimg {
  height: 50vh;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
} */
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,600;0,700;0,900;1,100;1,200;1,600&family=Montserrat:ital,wght@0,100;0,200;0,700;1,100;1,700;1,800;1,900&display=swap");

.work-video .work-banner {
  height: 100vh;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.work-heading {
  font-family: "Kanit", sans-serif;
}
.worker-header-subtext {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}
.contact-heading {
  font-family: "Kanit", sans-serif;
}

.work-video .video-container {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.work-heading > img {
  display: flex;
  width: 27%;
  margin: auto;
}

.logo {
  position: relative;
}

.work-heading > ul {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
}

@media screen and (max-width: 600px) {
  .service-media .video-container video {
    height: 92vh !important;
  }
  .work-heading > img {
    margin-top: 40px;
  }
  .work-heading > ul {
    justify-content: space-between;
  }
  .navbar-toggler {
    background: #b0947e;
    margin-left: 5rem;
  }
  .sideImg {
    display: none;
  }
  .work-heading h1 {
    font-size: 55px;
    line-height: 40px;
    margin-top: 30px;
  }
}
