.About {
  padding-bottom: 130px;
}

.about-heading {
  font-size: 50px;
  color: white;
  padding-top: 25px;
  padding-bottom: 25px;
  font-weight: 500;
}

.about-content {
  color: #bcbcbc;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.6em;
  letter-spacing: 0.4px;
}

.about-content h1 {
  color: #b3b3b3;
  font-family: "cera-light", Sans-serif;
  font-size: 25px;
  font-weight: 200;
}

.about-content > button {
  background-color: #f67510;
  color: WHITE;
  margin-top: 10%;
  width: 200px;
  height: 50px;
  font-weight: 600;
  border: none;
  display: none;
}

.Mask {
  width: 300px;
  display: flex;
  left: 70%;
  position: absolute;
  /* z-index: -9999; */
}

.about-img > h3 {
  margin-top: 90px;
  color: #f67510;
  -webkit-text-stroke: 1px #f67510;
  text-shadow: 0px 0px 4px #f67510;
  font-size: 70px;
  position: relative;
  z-index: 999999;
  font-weight: 600;
  text-align: center;
  letter-spacing: -2px;
}
.about-img > p > span {
  font-weight: 700;
  padding: 0px 30px;
  text-align: center;
  background-color: #f67510;
  border-radius: 10px 0px 10px 0px;
}

.about-img > p {
  font-size: 25px;
  color: white;
  font-weight: bolder;
  letter-spacing: 13px;
  margin-top: -28px;
  position: relative;
  z-index: 999999;
  text-align: center;
  font-weight: 200;
}

@media screen and (max-width: 600px) {
  .Mask {
    left: 50px;
    justify-content: center;
    display: block;
    width: 60%;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    margin-right: 50% !important;
    margin-left: 10%;
  }
  .about-heading {
    font-size: 50px;
  }
  .about-content {
    text-align: justify;
    padding: 0 15px;
    margin-top: 140px;
  }
  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about-img > h3 {
    font-size: 70px;
  }
  .about-img > p {
    font-size: 15px;
    margin-top: -12px;
    letter-spacing: 5px;
  }
  .about-flex {
    display: flex;
    flex-direction: column-reverse;
  }
}
