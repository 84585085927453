.orange {
  background-color: #f67510;
  height: 100%;
  padding: 30px;
}
.gett--in--touch {
  font-size: 30px;
  letter-spacing: 2px;
  font-weight: 300;
  display: flex;
  justify-content: center;
}
