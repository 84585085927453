.explore-work {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.product-card {
  width: 100%;
  height: 100%;
  /* border-radius: 1em; */
  padding: 1.9rem;
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 20px rgba(43, 8, 37, 0.2);
}

.product-card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: 0.5s;
}

.product-card-info {
  position: relative;
  z-index: 3;
  color: #f5f5f5;
  opacity: 0;
  width: 100%;
  height: 100%;
  transform: translateY(20%);
  transition: 0.5s;
}

/*Text*/

.text-title {
  text-align: center;
  font-size: 1.2 rem;
  font-weight: bold;
}

.text-body {
  margin-top: -20px !important;
  font-size: 15px;
  line-height: 1.2;
  /* text-align: justify; */
  color: white !important;
}

/*Image*/

.product-card-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.product-card:hover .product-card-img img {
  opacity: 0.5;
}

.product-card-img h2 {
  color: white;
  position: absolute;
  display: inline-block;
  width: 100%;
  background-color: #f67510;
  font-family: "Space Grotesk";
  font-size: 19px;
  font-weight: 600;
  height: auto;
  text-align: center;
  opacity: 1;
}

.product-card:hover .product-card-img h2 {
  opacity: 0;
}

.product-card:hover .product-card-info {
  opacity: 0.8;
  background-color: #f67510;
  width: 100%;
  height: 100%;
  transform: translateY(0);
}

.Explore > h1 {
  color: white;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
}

.side-button {
  text-align: right;
}

.side-button > button {
  background-color: #f67510;
  color: WHITE;
  width: 200px;
  height: 50px;
  font-weight: 600;
  border: none;
}

.sub-text {
  margin-top: 23px;
  line-height: 5px;
  font-size: 20px;
}

.build-para > p {
  text-align: center;
  color: #8f8f8f;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  line-height: 0.6em;
  letter-spacing: 0.4px;
}

.sub-links {
  display: flex;
  /* overflow-x: scroll; */
  /* margin-left: 60px; */
  /* padding-bottom: 50px; */
  /* margin-top: 50px; */
  padding-top: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sub-links > p > a {
  color: white;
  /* background-color: #f67510;
  padding: 10px 30px;
  border-radius: 5px; */
  text-decoration: none;
  text-transform: capitalize;
}

.sub-links > p > a:focus {
  background-color: #b95000;
  border: none;
}

.sub-links > p > button {
  background-color: #6f6f6f;
  transition: all 0.5s ease-in-out;
  border: none;
  color: white;
  font-size: 18px;
  width: 150px;
}

.sub-links > p > button:focus {
  background-color: #000000 !important;
  box-shadow: 0px 0px 10px 0px #b95000 !important;
  border-radius: 6px;
  font-size: 14px;
  letter-spacing: 3px;
  padding: 5px 0px;
}

.our-client {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.our-client h1 {
  text-align: center;
  color: white;
  font-size: 50px;
  padding-top: 12px;
  padding: 50px 50px;
}

.product-heading > h1 {
  font-size: 50px;
  padding: 50px 50px;
}

.product-gallery img:hover {
  color: #f67510;
}

.cus-menu-tabs {
  background-color: #f67510 !important;
  color: aliceblue;
  margin-bottom: 30px;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 600px) {
  .Explore > h1 {
    font-size: 35px;
    text-align: center;
    margin-top: 75px;
  }
  .side-button {
    text-align: center;
  }
  .cus-menu-tabs {
    margin-bottom: 0px;
  }
  .build-para > p {
    line-height: 25px;
    font-size: 22px;
    margin: auto;
    text-align: justify;
  }
  .sub-links > p > a {
    font-size: 13px;
    text-decoration: underline;
  }
  .sub-links {
    margin-left: 0px;
    gap: 10px;
    padding-top: 10px;
    /* margin-bottom: 27px; */
  }
  .our-client h1 {
    font-size: 35px;
    padding-bottom: 50px;
  }
  .our-client {
    height: 100%;
  }
  /* .our-client img {
    margin-top: -81px;
  } */
  .product-heading > h1 {
    font-size: 35px;
  }
}
