.orange {
  background-color: #f67510;
  height: 100%;
}

.idea {
  font-size: 35px;
}
.sub-heading {
  font-size: 30px;
}
.footer_box3,
.footer_box2,
.footer_box1 {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .idea {
    font-size: 40px;
  }
  .sub-heading {
    font-size: 25px;
  }
  .footer-logo > img {
    display: block;
    margin: auto;
  }
  .footer-logo > table {
    margin: auto;
    display: block;
    width: 83%;
  }
  .footer-icons {
    margin-left: 26px;
  }
  .footer_box3,
  .footer_box2,
  .footer_box1 {
    text-align: left;
  }
}
