.rlr-banner__media .rlr-callout {
  position: relative;
}

/* .navbar-left {
  display: flex;
  justify-content: center;
} */

.Logo {
  height: 60px;
  position: relative;
}

.navbar-button {
  background-color: #f67510;
  color: white;
  font-weight: 600;
}

.navbar-toggler {
  background-color: #f67510 !important;
}

.lineBars {
  color: white;
  font-size: 25px;
}

.navbar-expand-lg .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
  flex: auto;
}

.navbar-expand-lg .navbar-nav {
  flex-direction: row;
  flex: auto;
  justify-content: space-between;
}
.navbar-nav li {
  font-weight: 300 !important;
  font-size: 40px !important;
}

/*  */

@media screen and (max-width: 600px) {
  .Logo {
    height: 60px;
    margin-left: -35px;
  }
  .navbar-button {
    font-size: 12px !important;
  }
  .navbar-left {
    display: none;
  }
  .logoImage {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
}
